<template>
  <main v-if="$store.getters['page/isPageLoaded']" class="admin">
    <div class="admin-header">
      <nav class="admin-header__nav">
        <ul class="admin-header__items">
          <li class="admin-header__item__title">MOCHICA管理者画面</li>
          <li
            class="admin-header__item__toggle-tab"
            :class="{ 'is-active': activeId == 'adminCompanyList' }"
            @click="adminList"
          >
            会社一覧
          </li>
          <li
            class="admin-header__item__toggle-tab"
            :class="{ 'is-active': activeId == 'adminCompanyAnalytics' }"
            @click="adminAnalytics"
          >
            集計一覧
          </li>
          <li
            class="admin-header__item__toggle-tab"
            :class="{ 'is-active': activeId == 'adminCompanyInput' }"
            @click="adminInput"
          >
            会社登録
          </li>
          <li
            class="admin-header__item__toggle-tab"
            :class="{ 'is-active': activeId == 'adminLoginInput' }"
            @click="adminLoginInput"
          >
            ログイン画面編集
          </li>
          <li
            class="admin-header__item__toggle-tab"
            :class="{ 'is-active': activeId == 'adminInquiry' }"
            @click="adminInquiry"
          >
            プラン問合せ画面編集
          </li>
          <li class="admin-header__item__toggle-tab">
            <router-link to="/">MOCHICA 通常画面</router-link>
          </li>
        </ul>
      </nav>
      <div class="admin-header__item">
        <p
          class="admin-header__item__logout"
          @click.prevent="$store.dispatch('auth/LOGOUT')"
        >
          <img
            class="admin-header__item__logout-svg"
            src="@/assets/img/logout_blue.svg"
            alt
          />
          ログアウト
        </p>
      </div>
    </div>
    <div
      v-if="currentMode === 'adminCompanyList' && !isCompaniesLoading"
      class="admin-list-wrapper"
    >
      <div class="csv-download-btn" @click="downloadCompaniesCsv">
        CSVでダウンロード
      </div>
      <admin-list
        :companies="companies"
        class="admin-list"
        @companyIdExitstCheck="companyIdExistCheck"
      />
    </div>
    <local-page-loader
      v-if="currentMode === 'adminCompanyList' && isCompaniesLoading"
    />
    <admin-analytics
      v-if="currentMode === 'adminCompanyAnalytics'"
      :companies-count-plans="companiesCountPlans.data"
      :companies-datas="companiesDataList"
      :companies-total-datas="companiesTotalDataList.data"
      @downloadCompanyCsv="downloadCompanyCsv"
      @companyIdExitstCheck="companyIdExistCheck"
    />
    <admin-register-company
      v-if="currentMode === 'adminCompanyInput'"
      @cancelCompany="cancelCompany"
    />
    <admin-edit-login-page v-if="currentMode === 'adminLoginInput'" />
    <admin-edit-plan-inquiry v-if="currentMode === 'adminInquiry'" />
    <admin-edit-company
      v-if="currentMode === 'adminCompanyEdit'"
      :company-id="companyIdExist"
      :staff="$store.getters['staff/staff']"
      @successEdit="successEdit"
      @cancelCompany="cancelCompany"
    />
    <notification />
  </main>
</template>

<script>
import { onBeforeMount, onMounted, defineComponent, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import adminService from '@/services/admin';
import Notification from '@/components/ui/notification/components/Notification';
import LocalPageLoader from '@/components/ui/loaders/components/LocalPageLoader';
import AdminList from '@/components/page/admin/components/AdminList';
import AdminEditCompany from '@/components/page/admin/components/AdminEditCompany';
import AdminAnalytics from '@/components/page/admin/components/AdminAnalytics';
import AdminRegisterCompany from '@/components/page/admin/components/AdminRegisterCompany';
import AdminEditLoginPage from '@/components/page/admin/components/AdminEditLoginPage';
import AdminEditPlanInquiry from '@/components/page/admin/components/AdminEditPlanInquiry';

export default defineComponent({
  name: 'Admin',
  components: {
    Notification,
    LocalPageLoader,
    AdminList,
    AdminEditCompany,
    AdminAnalytics,
    AdminRegisterCompany,
    AdminEditLoginPage,
    AdminEditPlanInquiry,
  },
  setup(props, context) {
    const router = useRouter();
    const store = useStore();
    const currentMode = ref('adminCompanyList');
    const companyIdExist = ref(null);
    const companies = ref([]);
    const companiesCountPlans = reactive({ data: {} });
    const companiesDataList = ref([]);
    const companiesTotalDataList = reactive({ data: {} });
    const companiesPage = ref(1);
    const companiesLastPage = ref(1);
    const companiesCount = ref(1);
    const companiesLimit = ref(10000);
    const activeId = ref('adminCompanyList');
    const isCompaniesLoading = ref(false);

    const adminList = async () => {
      await getCompanies();
      activeId.value = 'adminCompanyList';
      currentMode.value = 'adminCompanyList';
    };
    const adminAnalytics = async () => {
      await getCompanyCountPlan();
      await getCompanyAnalyticsAll();
      activeId.value = 'adminCompanyAnalytics';
      currentMode.value = 'adminCompanyAnalytics';
    };
    const adminInput = () => {
      activeId.value = 'adminCompanyInput';
      currentMode.value = 'adminCompanyInput';
    };
    const adminLoginInput = () => {
      activeId.value = 'adminLoginInput';
      currentMode.value = 'adminLoginInput';
    };
    const adminInquiry = () => {
      activeId.value = 'adminInquiry';
      currentMode.value = 'adminInquiry';
    };
    const cancelCompany = () => {
      activeId.value = 'adminCompanyList';
      currentMode.value = 'adminCompanyList';
    };
    const getCompanies = async () => {
      isCompaniesLoading.value = true;
      const res = await adminService.getCompanies(
        companiesPage.value,
        companiesLimit.value,
      );
      if (res.success !== true) {
        companies.value = [];
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '企業リストが読み込めませんでした。',
          type: false,
        });
        isCompaniesLoading.value = false;
        return;
      }
      companies.value = res.companies.rows;
      currentMode.value = 'adminCompanyList';
      companiesCount.value = res.companies.count;
      companiesLastPage.value = res.lastPage;
      isCompaniesLoading.value = false;
    };
    const getCompanyCountPlan = async () => {
      const res = await adminService.getCompanyCountPlan();
      if (res.success !== true) {
        companiesCountPlans.data = {};
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: 'プランごとの企業稼動数が読み込めませんでした',
          type: false,
        });
        return;
      }
      companiesCountPlans.data = res.companyPlan;
    };
    const getCompanyAnalyticsAll = async () => {
      const res = await adminService.getCompanyAdminAnalytics();
      if (res.success !== true) {
        companiesDataList.value = [];
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '企業アナリティクスリストが読み込めませんでした。',
          type: false,
        });
      }
      companiesDataList.value = res.company_datas;
      companiesTotalDataList.data = res.total_company_datas;
      currentMode.value = 'adminCompanyAnalytics';
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: '企業アナリティクスリストを読み込みました。',
        type: true,
      });
    };
    const companyIdExistCheck = async payload => {
      if (payload !== null) {
        companyIdExist.value = payload;
        currentMode.value = 'adminCompanyEdit';
        return;
      }
      companyIdExist.value = null;
      currentMode.value = 'adminCompanyList';
    };
    const downloadCompanyCsv = async () => {
      const res = await adminService.getDownloadCompanyCsv();
      let blob;
      const csvName = 'mochica_companys_data.csv';
      const csv = [res];
      const type = { type: 'text/csv; charset=Shift-JIS' };
      const link = document.createElement('a');
      const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
      if (window.navigator.msSaveOrOpenBlob) {
        // for ie
        blob = new Blob([bom, csv], type);
        window.navigator.msSaveOrOpenBlob(blob, csvName);
      } else if (window.webkitURL && window.webkitURL.createObjectURL) {
        // for chrome (and safari)
        blob = new Blob([bom, csv], type);
        link.setAttribute('download', csvName);
        link.setAttribute('href', window.webkitURL.createObjectURL(blob));
        link.click();
        window.URL.revokeObjectURL(link.href);
      } else if (window.URL && window.URL.createObjectURL) {
        // for firefox
        blob = new Blob([bom, csv], type);
        link.setAttribute('download', csvName);
        link.setAttribute('href', window.URL.createObjectURL(blob));
        link.click();
        window.URL.revokeObjectURL(link.href);
      }
    };

    const downloadCompaniesCsv = async () => {
      const res = await adminService.getAdminCompaniesCsvDownload();
      let blob;
      const csvName = 'mochica-companies-list.csv';
      const csv = [res];
      const type = { type: 'text/csv; charset=Shift-JIS' };
      const link = document.createElement('a');
      const bom = new Uint8Array([0xef, 0xbb, 0xbf]);

      if (window.navigator.msSaveOrOpenBlob) {
        // for ie
        blob = new Blob([bom, csv], type);
        window.navigator.msSaveOrOpenBlob(blob, csvName);
      } else if (window.webkitURL && window.webkitURL.createObjectURL) {
        // for chrome (and safari)
        blob = new Blob([bom, csv], type);
        link.setAttribute('download', csvName);
        link.setAttribute('href', window.webkitURL.createObjectURL(blob));
        link.click();
        window.URL.revokeObjectURL(link.href);
      } else if (window.URL && window.URL.createObjectURL) {
        // for firefox
        blob = new Blob([bom, csv], type);
        link.setAttribute('download', csvName);
        link.setAttribute('href', window.URL.createObjectURL(blob));
        link.click();
        window.URL.revokeObjectURL(link.href);
      }
    };

    const successEdit = async () => {
      await getCompanies();
      currentMode.value = 'adminCompanyList';
    };

    // lifecycle
    onBeforeMount(async () => {
      if (store.getters['staff/staff'].admin !== 1) {
        router.replace('/');
      } else {
        await getCompanies();
      }
    });
    onMounted(async () => {
      if (store.getters['staff/staff'].admin !== 1) {
        router.replace('/');
      } else {
        await store.dispatch('page/SET_LOADED');
      }
    });

    return {
      currentMode,
      companyIdExist,
      companies,
      companiesCountPlans,
      companiesDataList,
      companiesTotalDataList,
      companiesPage,
      companiesLastPage,
      companiesCount,
      companiesLimit,
      isCompaniesLoading,
      activeId,
      adminList,
      adminAnalytics,
      adminInput,
      adminLoginInput,
      adminInquiry,
      cancelCompany,
      companyIdExistCheck,
      downloadCompanyCsv,
      downloadCompaniesCsv,
      successEdit,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';

.admin {
  min-height: 100vh;
  padding-bottom: 2rem;
}
.admin-header {
  @include flex_center($justify: space-between);
  background: #fff;
  box-shadow: $box_shadow;
  width: 100%;
  padding: 20px;
}
.admin-header__items {
  display: flex;
}
.admin-header__item__title {
  margin-right: 1.2rem;
  font-size: 1.5rem;
  letter-spacing: 0.05rem;
}
.admin-header__item__toggle-tab {
  color: $mochica_color_gray;
  padding-bottom: 0.4rem;
  border-bottom: 1px solid;
  transition: all ease 0.3s;
  cursor: pointer;
  &:hover {
    color: $mochica_color;
  }
  &.is-active {
    color: $mochica_color;
  }
  & + & {
    margin-left: 0.6rem;
  }
  &:not(:first-child) {
    margin-left: 20px;
  }
}
.admin-header__item__logout {
  @include flex_center();
  @include btn_hover();
  cursor: pointer;
}
.admin-header__item__logout-svg {
  fill: $mochica_color;
  width: 2rem;
  margin-right: 0.8rem;
}
.admin-list-wrapper {
  padding: 20px 30px 30px;
}
.pager-area-top {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  padding: 0 30px;
}
.pager-area-bottom {
  display: flex;
  justify-content: flex-end;
  padding: 0 30px;
  margin-bottom: 100px;
}

.csv-download-btn {
  width: fit-content;
  display: flex;
  justify-content: flex-start;
  background-color: #1899d6;
  color: #fff;
  padding: 8px 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  font-weight: bold;
  transition: all ease 0.3s;
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
}
</style>

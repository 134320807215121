<script setup lang="ts">
import { onMounted, ref, watchEffect } from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';

import BaseSelect from '@/components/ui/select/BaseSelect.vue';
import ToggleSwitch from '@/components/ui/toggleSwitch/ToggleSwitch.vue';
import EmptyContent from '@/components/ui/empty/EmptyContent.vue';

import MessagePlansManager from '@/services/message-plans';
import {
  MESSAGE_PLAN_KINDS,
  MESSAGE_PLAN_STATUSES,
  MESSAGE_PLAN_FIXED_END_MONTH,
} from '@/defines/messagePlans';

import type { MessagePlanKind, MessagePlanStatus } from '@/types/messagePlans';
import type { BaseMessagePlansResponse } from '@/services/message-plans';

const formattedToday = moment().format('YYYY-MM-DD');
const defaultMessagePlanKind = '0' as const;

const props = defineProps<{
  companyId: number;
  isDeactiveLineNotificationFlag: boolean;
}>();

const store = useStore();

const messagePlans = ref<BaseMessagePlansResponse['message_plans']>([]);
const disabledBulkUpdateButton = ref<boolean>(true);
const selectedIsDeactiveLineNotificationFlag = ref<boolean>(
  props.isDeactiveLineNotificationFlag,
);

const addMessagePlanFormData = ref<{
  kind: MessagePlanKind | typeof defaultMessagePlanKind;
  start_date: string;
}>({
  kind: defaultMessagePlanKind,
  start_date: formattedToday,
});
const isVisibleAddMessagePlanModal = ref<boolean>(false);

const formattedExpirationDate = () => {
  const startDate = moment(addMessagePlanFormData.value.start_date).format(
    'YYYY/MM/DD',
  );
  const endDate = moment(addMessagePlanFormData.value.start_date)
    .add(MESSAGE_PLAN_FIXED_END_MONTH, 'month')
    .subtract(1, 'day')
    .format('YYYY/MM/DD');
  return `${startDate} 〜 ${endDate}`;
};

const isValidStartDate = () =>
  moment(addMessagePlanFormData.value.start_date).isValid();

const closeModal = () => {
  isVisibleAddMessagePlanModal.value = false;
};

const submitAddMessagePlan = async () => {
  if (addMessagePlanFormData.value.kind === defaultMessagePlanKind) {
    closeModal();
    store.dispatch('notification/VISIBLE_NOTIFICATION', {
      message: '通数を選択してください',
      type: false,
    });
    return;
  }
  closeModal();
  const res = await MessagePlansManager.postMessagePlan({
    message_plan: {
      company_id: props.companyId,
      start_date: addMessagePlanFormData.value.start_date,
      kind: addMessagePlanFormData.value.kind,
      status: MESSAGE_PLAN_STATUSES.disabled,
    },
  });

  await initializeMessagePlans();

  store.dispatch('notification/VISIBLE_NOTIFICATION', {
    message: res.data.message,
    type: res.data.success,
  });
};
const bulkUpdateMessagePlans = async () => {
  disabledBulkUpdateButton.value = true;

  const targetMessagePlans = messagePlans.value.filter(
    messagePlan => !messagePlan.disabled,
  );
  const updateMessagePlans = targetMessagePlans.map(messagePlan => ({
    id: messagePlan.id,
    status: messagePlan.status,
  }));
  const res = await MessagePlansManager.bulkUpdateMessagePlans({
    company_id: props.companyId,
    message_plans: updateMessagePlans,
    is_line_cooperate_user: selectedIsDeactiveLineNotificationFlag.value,
  });
  if (res.data.success) {
    if (res.data.message_plans) {
      const mergedPlans = [
        ...messagePlans.value,
        ...res.data.message_plans,
      ].reduce((map, plan) => map.set(plan.id, plan), new Map());
      messagePlans.value = Array.from(mergedPlans.values());
    }
  }
  store.dispatch('notification/VISIBLE_NOTIFICATION', {
    message: res.data.message,
    type: res.data.success,
  });
};

const updateMessagePlansStatus = (
  messagePlan: BaseMessagePlansResponse['message_plans'][number],
  value: MessagePlanStatus,
) => {
  disabledBulkUpdateButton.value = false;
  messagePlan.status = value;
};

const updateIsDeactiveLineNotificationFlag = async () => {
  disabledBulkUpdateButton.value = false;
  selectedIsDeactiveLineNotificationFlag.value =
    !selectedIsDeactiveLineNotificationFlag.value;
};

const initializeMessagePlans = async () => {
  const res = await MessagePlansManager.fetchMessagePlans({
    company_id: props.companyId,
  });
  messagePlans.value = res.data.message_plans;
  addMessagePlanFormData.value.kind = defaultMessagePlanKind;
  addMessagePlanFormData.value.start_date = formattedToday;
};

watchEffect(
  () =>
    (selectedIsDeactiveLineNotificationFlag.value =
      props.isDeactiveLineNotificationFlag),
);

onMounted(initializeMessagePlans);
</script>

<template>
  <div class="contents-wrapper">
    <div class="contents">
      <div class="contents-box">
        <h2 class="contents-box-title">LINE通知メッセージ 通数一覧</h2>
        <div class="form-item pb-20 border-bottom">
          <label class="form-label">LINE通知メッセージ</label>
          <label class="switchLineNotification">
            <input
              v-model="selectedIsDeactiveLineNotificationFlag"
              type="checkbox"
              name="company-deactive-line-notification-flag"
              @click="updateIsDeactiveLineNotificationFlag"
            />
            <span class="slider round"></span>
          </label>
          <label class="form-subitem">
            ※あらかじめLINE通知メッセージの申請をして、承認が下りている必要があります。
          </label>
        </div>
        <div v-if="messagePlans.length > 0" class="table-wrapper">
          <table class="table">
            <thead>
              <th class="fit-left"><span>通数</span></th>
              <th class="fit-left"><span>有効期間</span></th>
              <th class="fit-left"><span>残り送信可能数</span></th>
              <th class="fit-left"><span>有効/無効</span></th>
            </thead>
            <tbody>
              <tr
                v-for="messagePlan in messagePlans"
                :key="messagePlan.id"
                :class="{ disabled: messagePlan.disabled }"
              >
                <td class="fit-left">{{ messagePlan.plan_message_count }}通</td>
                <td
                  class="fit-left"
                  :class="{ 'is-alert': messagePlan.has_expired_threshold }"
                >
                  {{ messagePlan.term }}
                </td>
                <td
                  class="fit-left"
                  :class="{ 'is-alert': messagePlan.has_max_count_threshold }"
                >
                  {{ messagePlan.remaining_message_count }}
                </td>
                <td class="fit-left">
                  <toggle-switch
                    v-model="messagePlan.status"
                    :true-value="MESSAGE_PLAN_STATUSES.enabled"
                    :false-value="MESSAGE_PLAN_STATUSES.disabled"
                    :disabled="messagePlan.disabled"
                    @update:model-value="
                      updateMessagePlansStatus(messagePlan, $event)
                    "
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <empty-content v-else class="empty-message-plan empty-flow">
          メッセージが購入されていません
        </empty-content>
        <button-base
          class="cancel-btn"
          button-text="キャンセル"
          :is-outlined="true"
          @click="$emit('cancelCompany')"
        />
        <button-base
          class="submit-btn update-message-plan btn-primary"
          button-text="LINE通知メッセージの設定を変更する"
          :disabled="disabledBulkUpdateButton"
          @click="bulkUpdateMessagePlans"
        />
      </div>
      <div class="contents-box">
        <h2 class="contents-box-title">LINE通知メッセージ 通数の追加</h2>
        <div class="form-item message-plan-kind">
          <label class="form-label">通数の選択</label>
          <base-select v-model="addMessagePlanFormData.kind">
            <option hidden :value="defaultMessagePlanKind">
              選択してください
            </option>
            <option
              v-for="kind in Object.keys(MESSAGE_PLAN_KINDS)"
              :key="kind"
              :value="kind.toString()"
            >
              {{ kind }}通
            </option>
          </base-select>
        </div>
        <div class="form-item">
          <label class="form-label">開始日</label>
          <div class="form-item-start-date">
            <el-date-picker
              id="el-input__inner"
              v-model="addMessagePlanFormData.start_date"
              type="date"
              format="YYYY/MM/DD"
              value-format="YYYY-MM-DD"
            />
            <div v-if="isValidStartDate()" class="form-item-term">
              有効期間は
              <span class="expiration-date">
                {{ formattedExpirationDate() }}
              </span>
              になります。
            </div>
          </div>
        </div>
        <button-base
          class="submit-btn add-message-plan btn-primary"
          button-text="通数を追加する"
          :disabled="
            addMessagePlanFormData.kind == defaultMessagePlanKind ||
            !isValidStartDate()
          "
          @click="isVisibleAddMessagePlanModal = true"
        />
      </div>
    </div>
    <modal-window
      :is-visible="isVisibleAddMessagePlanModal"
      title="通数追加の確認"
      :max-width="'570px'"
      :max-height="'90vh'"
      @close="closeModal"
      @clickOuter="closeModal"
    >
      <template #content>
        <div class="modal-contents">
          <div class="modal-contents-text">
            <p>
              {{ addMessagePlanFormData.kind }}通を{{
                moment(addMessagePlanFormData.start_date).format('YYYY/MM/DD')
              }}に追加します
            </p>
            <p class="caption">
              ※有効期間は
              <span class="expiration-date">
                {{ formattedExpirationDate() }}
              </span>
              になります。
            </p>
          </div>
        </div>
        <div class="modal-contents-bottom">
          <button-base
            type="button"
            :button-text="'キャンセル'"
            button-type="secondary"
            @click="closeModal"
          />
          <button-base
            type="button"
            :button-text="'通数を追加する'"
            :button-type="'primary'"
            @click="submitAddMessagePlan"
          />
        </div>
      </template>
    </modal-window>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@import '@/assets/datepicker.scss';
@include tabsWrapper;
@include tableStyle;

.border-bottom {
  border-bottom: 1px solid #f1f1f1;
}
.pb-20 {
  padding-bottom: 20px;
}
.contents-box {
  padding: 20px;
  background-color: $white;
  margin-bottom: 2rem;

  &-title {
    padding: 10px 16px;
    border-radius: 4px;
    background-color: $mochica_color_gray2;
    font-weight: bold;
    font-size: $font_16;
    line-height: 1.5;
    margin-bottom: 20px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  :deep(button.btn-base.btn-primary) {
    border-radius: 24px;
  }

  :deep(.base-select) {
    height: 44px;
  }

  :deep(.el-input__inner) {
    width: 320px;
    height: 44px;
  }
}

.cancel-btn,
.submit-btn {
  width: 100%;
  height: 48px;
  margin-top: 24px;
  border-radius: 24px !important;
}

.table-wrapper {
  overflow-y: scroll;
  min-height: initial;
  max-height: 250px;

  td {
    font-weight: bold;

    &.is-alert {
      color: $mochica_color_red;
    }
  }

  tr {
    &.disabled {
      opacity: 0.2;
      pointer-events: none;
    }
  }
}

.form-item {
  margin-bottom: 20px;

  &.message-plan-kind {
    max-width: 320px;
  }

  .form-item-start-date {
    display: flex;
    align-items: center;
  }

  .form-item-term {
    margin-left: 210px;
  }
}

.form-label {
  display: block;
  margin-bottom: 12px;
  font-size: $font_14;
  font-weight: bold;
}

.expiration-date {
  font-weight: bold;
}

.btn-submit.add-message-plan {
  margin-bottom: 28px;
}

.modal-contents {
  padding: 24px;

  &-text {
    white-space: pre-line;
    text-align: center;

    .caption {
      margin-top: 16px;
      font-size: $font_12;
      color: $mochica_color_gray;
    }
  }

  &-bottom {
    display: flex;
    gap: 8px;
    padding: 0 24px 24px 24px;

    button {
      height: 52px;
      width: 100%;
    }
  }
}

.switchLineNotification {
  position: relative;
  display: inline-block;
  width: 54px;
  height: 28px;
  input {
    display: none;
    &:checked + .slider {
      background-color: $mochica_color;
    }
    &:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }
    &:focus + .slider {
      box-shadow: 0 0 1px $mochica_color_red;
    }
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $mochica_color_red;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    &:before {
      position: absolute;
      content: '';
      height: 20px;
      width: 20px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
    &.round {
      border-radius: 34px;
      &:before {
        border-radius: 50%;
      }
    }
  }
}
.form-subitem {
  display: block;
  font-size: $font_14;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  color: #777;
}
</style>

<template>
  <div class="admin-list">
    <table class="table">
      <thead class="thead">
        <tr>
          <th class="th">ログインID</th>
          <th class="th">操作</th>
          <th class="th">アカウント</th>
          <th class="th">アカウント納品</th>
          <th class="th">アカウント納品日</th>
          <th class="th">会社ID</th>
          <th class="th">法人名</th>
          <th class="th">LINEアカウント登録企業名</th>
          <th class="th">基本年間プラン</th>
          <th class="th">現在利用プラン</th>
          <th class="th">アップグレードプラン①</th>
          <th class="th">アップグレード①開始月</th>
          <th class="th">アップグレードプラン②</th>
          <th class="th">アップグレード②開始月</th>
          <th class="th">Email</th>
          <th class="th">LINE＠ ID</th>
          <th class="th">無料開始月</th>
          <th class="th">有料開始月</th>
          <th class="th">有料終了月</th>
          <th class="th">利用期間</th>
          <th class="th">作成日</th>
          <th class="th">備考欄</th>
        </tr>
      </thead>
      <tbody class="tbody">
        <tr v-for="companyData in companies" :key="companyData.id" class="tr">
          <td class="td">{{ companyData.id }}</td>
          <td class="td edit">
            <button
              type="button"
              class="td-button"
              @click="$emit('companyIdExitstCheck', companyData.id)"
            >
              編集
            </button>
          </td>
          <td class="td account centering">
            <span
              class="company-status"
              :class="{ active: !companyData.deactive }"
            >
              {{ companyData.deactive === true ? '無効' : '有効' }}
            </span>
          </td>
          <td class="td account-delivery centering">
            <span
              class="company-issuestatus"
              :class="{ active: !companyData.issue }"
            >
              {{ companyData.issue === true ? '済み' : '未納' }}
            </span>
          </td>
          <td class="td account-delivery-date">
            {{ $utils.parseDateTime(companyData.issue_at, 'YYYY/MM/DD') }}
          </td>
          <td class="td">{{ companyData.sub_id }}</td>
          <td class="td">{{ companyData.cooperation_name }}</td>
          <td class="td">{{ companyData.name }}</td>
          <td class="td basic-plan-name plan_items">
            <span
              v-if="companyData.basic_plan_id"
              class="plan_item"
              :class="getPlanDataById(companyData.basic_plan_id).name"
            >
              {{ getPlanDataById(companyData.basic_plan_id).name_ja }}
            </span>
          </td>
          <td class="td plan-name plan_items">
            <span
              class="plan_item"
              :class="getPlanDataById(companyData.plan).name"
            >
              {{ getPlanDataById(companyData.plan).name_ja }}
            </span>
          </td>
          <td class="td first-upgrade-plan-name plan_items">
            <span
              v-if="companyData.first_upgrade_plan_id"
              class="plan_item"
              :class="getPlanDataById(companyData.first_upgrade_plan_id).name"
            >
              {{ getPlanDataById(companyData.first_upgrade_plan_id).name_ja }}
            </span>
          </td>

          <td class="td first_upgrade_at">
            {{
              companyData.first_upgrade_at
                ? $utils.parseDateTime(companyData.first_upgrade_at, 'YYYY/MM')
                : ''
            }}
          </td>

          <td class="td second-upgrade-plan-name plan_items">
            <span
              v-if="companyData.second_upgrade_plan_id"
              class="plan_item"
              :class="getPlanDataById(companyData.second_upgrade_plan_id).name"
            >
              {{ getPlanDataById(companyData.second_upgrade_plan_id).name_ja }}
            </span>
          </td>

          <td class="td second_upgrade_at">
            {{
              companyData.second_upgrade_at
                ? $utils.parseDateTime(companyData.second_upgrade_at, 'YYYY/MM')
                : ''
            }}
          </td>
          <td class="td">{{ companyData.email }}</td>
          <td class="td">{{ companyData.line_id }}</td>
          <td class="td free_start_at">
            {{
              companyData.free_start_at
                ? $utils.parseDateTime(companyData.free_start_at, 'YYYY/MM')
                : ''
            }}
          </td>

          <td class="td paid_start_at">
            {{
              companyData.paid_start_at
                ? $utils.parseDateTime(companyData.paid_start_at, 'YYYY/MM')
                : ''
            }}
          </td>

          <td class="td paid_end_at">
            {{
              companyData.paid_end_at
                ? $utils.parseDateTime(companyData.paid_end_at, 'YYYY/MM')
                : ''
            }}
          </td>
          <td class="td">{{ companyData.usage_period }}</td>
          <td class="td created-at">
            {{
              companyData.created_at
                ? $utils.parseDateTime(companyData.created_at, 'YYYY/MM/DD')
                : ''
            }}
          </td>
          <td class="td">{{ companyData.memo }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

import { getPlanDataById } from '@/defines/plan';

export default defineComponent({
  name: 'AdminList',
  props: {
    companies: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['companyIdExitstCheck'],
  setup(props, context) {
    return { getPlanDataById };
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';

.admin-list {
  width: 100%;
  overflow-x: auto;
  background: #fff;
  .table {
    min-width: 100%;
    .thead {
      color: $mochica_color;
      .th {
        padding: 8px;
        text-align: left;
        word-break: keep-all;
        background: #eff9ff;
        border: 1px solid #e2e2e2;
      }
    }
    .tbody {
      .tr {
        background: #fff;
        &:nth-of-type(2n) {
          background: #fdfdfd;
        }
        &:hover {
          background: #f9f9f9;
        }
        .td {
          border: 1px solid #e2e2e2;
          line-height: 1.8;
          white-space: nowrap;
          padding: 8px;
          &.edit {
            width: 96px;
            min-width: 96px;
          }
          &.account {
            width: 89px;
          }
          &.upload {
            width: 103px;
          }
          &.account-delivery {
            width: 117px;
          }
          &.account-delivery-date {
            width: 132px;
          }
          &.plan-name {
            width: 167px;
          }
          &.created-at {
            width: 106px;
          }
          &.centering {
            text-align: center;
          }
          &.plan_items {
            .plan_item {
              padding: 5px 10px;
              border-radius: 2rem;
              font-weight: bold;
              border: 1px solid;
              &.entry {
                background-color: #ea6f26;
                color: #fff;
              }
              &.light,
              &.light500,
              &.light700,
              &.light900 {
                background-color: #1899d6;
                color: #fff;
              }
              &.standard {
                background-color: #43a048;
                color: #fff;
              }
              &.minimum {
                background-color: #de6028;
                color: #fff;
              }
              &.middle {
                background-color: #3a8bd0;
                color: #fff;
              }
            }
          }
          .td-button {
            background: #ffffff;
            color: #3a8ee6;
            width: 100%;
            padding: 0.4rem;
            border-radius: 2rem;
            cursor: pointer;
            border: 1px solid;
            transition: all ease 0.3s;
            backface-visibility: hidden;
            &:hover {
              background: #3a8ee6;
              color: #ffffff;
            }
          }
          .company-status {
            font-weight: 600;
            border-radius: 30px;
            padding: 4px 18px;
            background-color: $mochica_color_red;
            color: #fff;
            &.active {
              background-color: $mochica_color;
              color: #fff;
            }
          }
          .company-fileuploadstatus {
            font-weight: 600;
            border-radius: 30px;
            padding: 4px 18px;
            background-color: $mochica_color;
            color: #fff;
            &.active {
              background-color: $mochica_color_red;
              color: #fff;
            }
          }
          .company-issuestatus {
            font-weight: 600;
            border-radius: 30px;
            padding: 4px 18px;
            background-color: $mochica_color;
            color: #fff;
            &.active {
              background-color: $mochica_color_red;
              color: #fff;
            }
          }
        }
      }
    }
  }
}
</style>

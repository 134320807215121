const mochicaPlans = [
  // エントリープラン（廃止）
  {
    id: 0,
    name: 'entry',
    name_ja: 'エントリープラン',
    name_short: 'エントリー',
    applicant_limit: 100,
    file_storage: 0,
    delete_applicant: false,
    is_show: false,
    color: '#ea6f26',
  },
  {
    id: 3,
    name: 'free',
    name_ja: 'フリープラン',
    name_short: 'フリー',
    applicant_limit: 30,
    file_storage: 15,
    delete_applicant: false,
    is_show: false,
    color: '#ea6f26',
  },
  {
    id: 1,
    name: 'light',
    name_ja: 'ライトプラン',
    name_short: 'ライト',
    applicant_limit: 300,
    file_storage: 15,
    delete_applicant: true,
    is_show: true,
    color: '#1899d6',
  },
  {
    id: 4,
    name: 'light500',
    name_ja: 'ライトプラン500',
    name_short: 'ライト500',
    applicant_limit: 500,
    file_storage: 15,
    delete_applicant: true,
    is_show: true,
    color: '#1899d6',
  },
  {
    id: 5,
    name: 'light700',
    name_ja: 'ライトプラン700',
    name_short: 'ライト700',
    applicant_limit: 700,
    file_storage: 15,
    delete_applicant: true,
    is_show: true,
    color: '#1899d6',
  },
  {
    id: 6,
    name: 'light900',
    name_ja: 'ライトプラン900',
    name_short: 'ライト900',
    applicant_limit: 900,
    file_storage: 15,
    delete_applicant: true,
    is_show: true,
    color: '#1899d6',
  },
  {
    id: 7,
    name: 'data',
    name_ja: 'データ利用プラン',
    name_short: 'データ',
    applicant_limit: 50,
    file_storage: 15,
    delete_applicant: true,
    is_show: true,
    color: '#999999',
  },
  {
    id: 8,
    name: 'minimum',
    name_ja: 'ミニマムプラン',
    name_short: 'ミニマム',
    applicant_limit: 500,
    file_storage: 15,
    delete_applicant: true,
    is_show: true,
    color: '#de6028',
  },
  {
    id: 9,
    name: 'middle',
    name_ja: 'ミドルプラン',
    name_short: 'ミドル',
    applicant_limit: 2000,
    file_storage: 15,
    delete_applicant: true,
    is_show: true,
    color: '#3a8bd0',
  },
  {
    id: 2,
    name: 'standard',
    name_ja: 'スタンダードプラン',
    name_short: 'スタンダード',
    applicant_limit: 99999999,
    file_storage: 15,
    delete_applicant: true,
    is_show: true,
    color: '#43a048',
  },
];

const PLAN_IDS = Object.freeze({
  entry: mochicaPlans.find(plan => plan.name === 'entry').id,
  free: mochicaPlans.find(plan => plan.name === 'free').id,
  light: mochicaPlans.find(plan => plan.name === 'light').id,
  light500: mochicaPlans.find(plan => plan.name === 'light500').id,
  light700: mochicaPlans.find(plan => plan.name === 'light700').id,
  light900: mochicaPlans.find(plan => plan.name === 'light900').id,
  data: mochicaPlans.find(plan => plan.name === 'data').id,
  minimum: mochicaPlans.find(plan => plan.name === 'minimum').id,
  middle: mochicaPlans.find(plan => plan.name === 'middle').id,
  standard: mochicaPlans.find(plan => plan.name === 'standard').id,
});

const FEE_NUMBERS = Object.freeze({
  data: '5,000',
  minimum: '35,000',
  middle: '50,000',
  standard: '65,000',
});

const APPLICANT_NUMBERS = Object.freeze({
  data: '50',
  minimum: '500',
  middle: '2,000',
  standard: '無制限',
});

const getPlanDataById = planId => mochicaPlans.find(plan => plan.id === planId);
const getIsNewPlanById = planId =>
  planId === 7 || planId === 8 || planId === 9 || planId === 2;

export {
  mochicaPlans,
  getPlanDataById,
  getIsNewPlanById,
  PLAN_IDS,
  FEE_NUMBERS,
  APPLICANT_NUMBERS,
};

<template>
  <div>
    <h2 class="admin-line_setting_heading">LINEの設定</h2>
    <div class="line-settings-row pt-20 pb-20 border-bottom">
      <div class="line-settings-item">
        <label class="line-settings-label">
          LINE アカウント登録企業名
          <span class="red">※</span>
        </label>
        <input
          v-model="selectedName"
          class="line-settings-input"
          :class="{ 'is-danger': errors.selectedName.isValid !== true }"
          maxlength="64"
          @input="
            validateOnInput('selectedName', selectedName, validateRequire)
          "
        />
        <span v-if="errors.selectedName.isValid !== true" class="fa-warning">
          {{ errors.selectedName.message }}
        </span>
      </div>
      <div class="line-settings-item">
        <label class="line-settings-label">LINE チャンネルシークレット</label>
        <input
          v-model="selectedLineChannelSecret"
          class="line-settings-input"
          maxlength="32"
        />
      </div>
    </div>
    <div class="line-settings-row pt-20 pb-20 border-bottom">
      <div class="line-settings-item">
        <label class="line-settings-label">LINE アクセストークン</label>
        <input
          v-model="selectedLineAccessToken"
          class="line-settings-input"
          maxlength="172"
        />
      </div>
      <div class="line-settings-item">
        <label class="line-settings-label">LINE ID</label>
        <input
          v-model="selectedLineId"
          class="line-settings-input"
          maxlength="16"
        />
      </div>
    </div>
    <div class="line-settings-row pt-20 pb-20 border-bottom">
      <div class="line-settings-item">
        <label class="line-settings-label">LINE QR</label>
        <input
          v-model="selectedLineQr"
          class="line-settings-input"
          maxlength="255"
        />
      </div>
      <div class="line-settings-item">
        <label class="line-settings-label">LINE 友達URL</label>
        <input
          v-model="selectedLineUrl"
          class="line-settings-input"
          :class="{ 'is-danger': errors.selectedLineUrl.isValid !== true }"
          maxlength="150"
          @input="
            validateOnInput(
              'selectedLineUrl',
              selectedLineUrl,
              validateUrl,
              false,
            )
          "
        />
        <span v-if="errors.selectedLineUrl.isValid !== true" class="fa-warning">
          {{ errors.selectedLineUrl.message }}
        </span>
      </div>
    </div>
    <div class="line-settings-row pt-20 pb-20">
      <div class="line-settings-item">
        <label class="line-settings-label">LINE Webhook</label>
        <input
          v-model="selectedLineWebhook"
          class="line-settings-input"
          maxlength="9"
        />
      </div>
    </div>
    <button-base
      class="cancel-btn"
      button-text="キャンセル"
      :is-outlined="true"
      @click="$emit('cancelCompany')"
    />
    <button-base
      class="submit-btn"
      button-text="LINEの設定を変更する"
      :is-loading="isLoading"
      @click="updateCompanyLineSettings"
    />
  </div>
</template>

<script>
import { defineComponent, ref, watchEffect } from 'vue';
import { useStore } from 'vuex';

import adminService from '@/services/admin';
import useValidation from '@/composables/useValidation';

export default defineComponent({
  name: 'AdminLineSettings',
  props: {
    companyId: {
      type: Number,
      default: null,
    },
    name: {
      type: String,
      default: '',
    },
    lineChannelSecret: {
      type: String,
      default: '',
    },
    lineAccessToken: {
      type: String,
      default: '',
    },
    lineId: {
      type: String,
      default: '',
    },
    lineQr: {
      type: String,
      default: '',
    },
    lineUrl: {
      type: String,
      default: '',
    },
    lineWebhook: {
      type: String,
      default: '',
    },
  },
  emits: ['onSelectedPlanId'],
  setup(props, context) {
    const {
      errors,
      resetError,
      resetErrors,
      validateOnInput,
      validateRequire,
      validateUrl,
    } = useValidation(['selectedName', 'selectedLineUrl']);
    const store = useStore();
    const isLoading = ref(false);
    const selectedName = ref(props.name);
    const selectedLineChannelSecret = ref(props.lineChannelSecret);
    const selectedLineAccessToken = ref(props.lineAccessToken);
    const selectedLineId = ref(props.lineId);
    const selectedLineQr = ref(props.lineQr);
    const selectedLineUrl = ref(props.lineUrl);
    const selectedLineWebhook = ref(props.lineWebhook);

    // methods
    const validateAll = () => {
      resetErrors();
      let isValid = true;
      if (!validateRequire('selectedName', selectedName.value)) isValid = false;
      if (!validateUrl('selectedLineUrl', selectedLineUrl.value, false)) {
        isValid = false;
      }
      return isValid;
    };

    const updateCompanyLineSettings = async () => {
      if (validateAll() !== true) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          type: false,
          message: '入力内容を確認して下さい',
        });
        return;
      }
      isLoading.value = true;
      const res = await adminService.changeLineSettings(
        props.companyId,
        selectedName.value,
        selectedLineChannelSecret.value,
        selectedLineAccessToken.value,
        selectedLineId.value,
        selectedLineQr.value,
        selectedLineUrl.value,
        selectedLineWebhook.value,
      );
      isLoading.value = false;
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        type: res.success,
        message: res.message,
      });
    };

    // watch
    watchEffect(() => {
      selectedName.value = props.name;
      selectedLineChannelSecret.value = props.lineChannelSecret;
      selectedLineAccessToken.value = props.lineAccessToken;
      selectedLineId.value = props.lineId;
      selectedLineQr.value = props.lineQr;
      selectedLineUrl.value = props.lineUrl;
      selectedLineWebhook.value = props.lineWebhook;
    });

    return {
      errors,
      isLoading,
      selectedName,
      selectedLineChannelSecret,
      selectedLineAccessToken,
      selectedLineId,
      selectedLineQr,
      selectedLineUrl,
      selectedLineWebhook,
      resetError,
      validateOnInput,
      validateRequire,
      validateUrl,
      updateCompanyLineSettings,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.border-bottom {
  border-bottom: 1px solid #f1f1f1;
}
.pb-20 {
  padding-bottom: 20px;
}
.pt-20 {
  padding-top: 20px;
}
span.red {
  color: #f00;
}
input:focus.is-danger,
.is-danger {
  border: 1px $mochica_color_red solid;
}
.fa-warning {
  color: $mochica_color_red;
  padding-top: 4px;
  font-size: 1.2rem;
}
.cancel-btn,
.submit-btn {
  width: 100%;
  height: 48px;
  margin-top: 24px;
  border-radius: 24px !important;
}
.line-settings-label {
  display: block;
  margin-bottom: 10px;
  font-size: 1.2rem;
  font-weight: bold;
}
.select-plan {
  width: 67.5%;
  margin-bottom: 20px;
  padding: 5px;
  border-radius: 6px;
  border: 1px solid #ccc;
}
.admin-line_setting_heading {
  padding: 10px 16px;
  background: $mochica_color_gray2;
  font-weight: bold;
  border-radius: 4px;
  font-size: $font_16;
  margin-bottom: 20px;
}
.line-settings-input {
  display: block;
  border: 1px solid $border_color_gray;
  padding: 0.4rem;
  width: 320px;
  height: 44px;
  padding: 16px;
  border-radius: 4px !important;
}
.line-settings-row {
  display: flex;
}
.line-settings-item {
  width: 100%;
  margin-right: 20px;
  .btn-availability {
    line-height: 0px;
  }
}
</style>

<template>
  <div>
    <h2 class="admin-plan_setting_heading">プランの設定</h2>
    <div class="plan-settings-row pb-20 border-bottom">
      <div class="plan-settings-item">
        <h2>
          <label class="plan-settings-label">現在利用プラン</label>
        </h2>
        <p>{{ selectedPlanData.name_ja }}</p>
      </div>
    </div>
    <div class="plan-settings-row pt-20 pb-20 border-bottom">
      <div class="plan-settings-item">
        <h2>
          <label class="plan-settings-label">
            基本年間プラン
            <span class="red">※</span>
          </label>
        </h2>
        <base-select v-model="selectedBasicPlanId" required>
          <option v-for="plan in mochicaPlans" :key="plan.id" :value="plan.id">
            {{ plan.name_ja }}
          </option>
        </base-select>
      </div>
    </div>
    <div class="plan-settings-row pt-20 pb-20 border-bottom">
      <div class="plan-settings-item">
        <h2>
          <label class="plan-settings-label">応募者数</label>
        </h2>
        <p>{{ applicantsCount }}名</p>
      </div>
    </div>
    <div class="plan-settings-row pt-20 pb-20 border-bottom">
      <div class="plan-settings-item">
        <label class="plan-settings-label">アップグレードプラン①</label>
        <base-select v-model="selectedFirstUpgradePlanId">
          <option :value="0" selected>選択してください</option>
          <option v-for="plan in mochicaPlans" :key="plan.id" :value="plan.id">
            {{ plan.name_ja }}
          </option>
        </base-select>
      </div>
      <div class="plan-settings-item">
        <label class="plan-settings-label">
          アップグレードプラン① - 開始月
        </label>
        <el-date-picker
          v-model="selectedFirstUpgradeAt"
          class="upgrade-plan-date"
          type="month"
          format="YYYY/MM"
          value-format="YYYY-MM-DD"
          name="com_first_upgrade_at"
        />
      </div>
    </div>
    <div class="plan-settings-row pt-20 pb-20 border-bottom">
      <div class="plan-settings-item">
        <label class="plan-settings-label">アップグレードプラン②</label>
        <base-select v-model="selectedSecondUpgradePlanId">
          <option :value="0" selected>選択してください</option>
          <option v-for="plan in mochicaPlans" :key="plan.id" :value="plan.id">
            {{ plan.name_ja }}
          </option>
        </base-select>
      </div>
      <div class="plan-settings-item">
        <label class="plan-settings-label">
          アップグレードプラン② - 開始月
        </label>
        <el-date-picker
          v-model="selectedSecondUpgradeAt"
          class="upgrade-plan-date"
          type="month"
          format="YYYY/MM"
          value-format="YYYY-MM-DD"
          name="com_first_upgrade_at"
        />
      </div>
    </div>
    <div class="plan-settings-row pt-20 pb-20 border-bottom">
      <div class="plan-settings-item">
        <label class="plan-settings-label">無料プラン開始月</label>
        <el-date-picker
          v-model="selectedFreeStartAt"
          class="upgrade-plan-date"
          type="month"
          format="YYYY/MM"
          value-format="YYYY-MM-DD"
          name="com_first_upgrade_at"
        />
      </div>
    </div>
    <div class="plan-settings-row pt-20 pb-20 border-bottom">
      <div class="plan-settings-item">
        <label class="plan-settings-label">有料プラン開始月</label>
        <el-date-picker
          v-model="selectedPaidStartAt"
          class="upgrade-plan-date"
          type="month"
          format="YYYY/MM"
          value-format="YYYY-MM-DD"
          name="com_first_upgrade_at"
        />
      </div>
      <div class="plan-settings-item">
        <label class="plan-settings-label">有料プラン終了月</label>
        <el-date-picker
          v-model="selectedPaidEndAt"
          class="upgrade-plan-date"
          type="month"
          format="YYYY/MM"
          value-format="YYYY-MM-DD"
          name="com_first_upgrade_at"
        />
      </div>
    </div>
    <div class="plan-settings-row pt-20 pb-20 border-bottom">
      <div class="plan-settings-item">
        <h2>
          <label class="plan-settings-label">利用期間</label>
        </h2>
        <p>{{ selectedUsagePeriod }}</p>
      </div>
    </div>
    <div class="plan-settings-row pt-20 pb-20">
      <div class="plan-settings-item">
        <label class="plan-settings-label">備考欄</label>
        <input
          v-model="selectedMemo"
          class="plan-settings-input"
          maxlength="50"
        />
      </div>
    </div>
    <button-base
      class="cancel-btn"
      button-text="キャンセル"
      :is-outlined="true"
      @click="$emit('cancelCompany')"
    />
    <button-base
      class="submit-btn"
      button-text="プラン設定を変更する"
      :is-loading="isLoading"
      @click="updateCompanyPlanSettings"
    />
  </div>
</template>

<script>
import { defineComponent, computed, ref, watchEffect } from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';

import BaseSelect from '@/components/ui/select/BaseSelect.vue';
import adminService from '@/services/admin';
import { mochicaPlans, getPlanDataById } from '@/defines/plan';

export default defineComponent({
  name: 'AdminPlanSettings',
  components: {
    BaseSelect,
  },
  props: {
    companyId: {
      type: Number,
      default: null,
    },
    planId: {
      type: Number,
      default: null,
    },
    basicPlanId: {
      type: Number,
      default: null,
    },
    firstUpgradePlanId: {
      type: Number,
      default: 0,
    },
    firstUpgradeAt: {
      type: String,
      default: null,
    },
    secondUpgradePlanId: {
      type: Number,
      default: 0,
    },
    secondUpgradeAt: {
      type: String,
      default: null,
    },
    freeStartAt: {
      type: String,
      default: null,
    },
    paidStartAt: {
      type: String,
      default: null,
    },
    paidEndAt: {
      type: String,
      default: null,
    },
    usagePeriod: {
      type: Number,
      default: 0,
    },
    memo: {
      type: String,
      default: '',
    },
    applicantsCount: {
      type: Number,
      default: 0,
    },
  },
  emits: ['refreshCompanyData'],
  setup(props, context) {
    const store = useStore();
    const isLoading = ref(false);
    const selectedPlanId = ref(props.planId);
    const selectedBasicPlanId = ref(props.basicPlanId);
    const selectedFirstUpgradePlanId = ref(props.firstUpgradePlanId);
    const selectedFirstUpgradeAt = ref(props.firstUpgradeAt);
    const selectedSecondUpgradePlanId = ref(props.secondUpgradePlanId);
    const selectedSecondUpgradeAt = ref(props.secondUpgradeAt);
    const selectedFreeStartAt = ref(props.freeStartAt);
    const selectedPaidStartAt = ref(props.paidStartAt);
    const selectedPaidEndAt = ref(props.paidEndAt);
    const selectedUsagePeriod = ref(props.usagePeriod);
    const selectedMemo = ref(props.memo);

    // computed
    const selectedPlanData = computed(() => {
      const plan = getPlanDataById(props.planId);
      return plan || {};
    });

    const updateCompanyPlanSettings = async () => {
      isLoading.value = true;
      const res = await adminService.changePlanSettings(
        props.companyId,
        parseInt(selectedBasicPlanId.value),
        parseInt(selectedFirstUpgradePlanId.value) === 0
          ? null
          : parseInt(selectedFirstUpgradePlanId.value),
        selectedFirstUpgradeAt.value
          ? `${moment(selectedFirstUpgradeAt.value).format(
              'YYYY-MM-DD',
            )} 09:00:00`
          : null,
        parseInt(selectedSecondUpgradePlanId.value) === 0
          ? null
          : parseInt(selectedSecondUpgradePlanId.value),
        selectedSecondUpgradeAt.value
          ? `${moment(selectedSecondUpgradeAt.value).format(
              'YYYY-MM-DD',
            )} 09:00:00`
          : null,
        selectedFreeStartAt.value
          ? `${moment(selectedFreeStartAt.value).format('YYYY-MM-DD')} 09:00:00`
          : null,
        selectedPaidStartAt.value
          ? `${moment(selectedPaidStartAt.value).format('YYYY-MM-DD')} 09:00:00`
          : null,
        selectedPaidEndAt.value
          ? `${moment(selectedPaidEndAt.value).format('YYYY-MM-DD')} 09:00:00`
          : null,
        selectedMemo.value,
      );
      isLoading.value = false;
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        type: res.success,
        message: res.message,
      });
      context.emit('refreshCompanyData');
    };

    // watch
    watchEffect(() => {
      selectedPlanId.value = props.planId;
      selectedBasicPlanId.value = props.basicPlanId;
      selectedFirstUpgradePlanId.value = props.firstUpgradePlanId;
      selectedSecondUpgradePlanId.value = props.secondUpgradePlanId;
      selectedFirstUpgradeAt.value = props.firstUpgradeAt;
      selectedSecondUpgradeAt.value = props.secondUpgradeAt;
      selectedFreeStartAt.value = props.freeStartAt;
      selectedPaidStartAt.value = props.paidStartAt;
      selectedPaidEndAt.value = props.paidEndAt;
      selectedUsagePeriod.value = props.usagePeriod;
      selectedMemo.value = props.memo;
    });

    return {
      isLoading,
      mochicaPlans: mochicaPlans.filter(plan => plan.is_show === true),
      selectedPlanData,
      selectedPlanId,
      selectedBasicPlanId,
      selectedFirstUpgradePlanId,
      selectedFirstUpgradeAt,
      selectedSecondUpgradePlanId,
      selectedSecondUpgradeAt,
      selectedFreeStartAt,
      selectedPaidStartAt,
      selectedPaidEndAt,
      selectedUsagePeriod,
      selectedMemo,
      updateCompanyPlanSettings,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.border-bottom {
  border-bottom: 1px solid #f1f1f1;
}
.pb-20 {
  padding-bottom: 20px;
}
.pt-20 {
  padding-top: 20px;
}
span.red {
  color: #f00;
}
.cancel-btn,
.submit-btn {
  width: 100%;
  height: 48px;
  margin-top: 24px;
  border-radius: 24px !important;
}
.plan-settings-label {
  display: block;
  margin-bottom: 10px;
  font-size: 1.2rem;
  font-weight: bold;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('../../../../assets/img/arrow_down_mini_blue.svg');
  background-position: right 10px center;
  background-size: auto 10px;
}
.admin-plan_setting_heading {
  padding: 10px 16px;
  background: $mochica_color_gray2;
  font-weight: bold;
  border-radius: 4px;
  font-size: $font_16;
  margin-bottom: 20px;
}
.plan-settings-input {
  display: block;
  border: 1px solid $border_color_gray;
  padding: 0.4rem;
  width: 320px;
  height: 44px;
  padding: 16px;
  border-radius: 4px !important;
}
.plan-settings-row {
  display: flex;
}
.plan-settings-item {
  width: 100%;
  margin-right: 20px;
  .btn-availability {
    line-height: 0px;
  }

  :deep(.el-input__inner) {
    width: 320px;
    height: 44px;
  }

  :deep(.wrapper) {
    width: 320px;
  }

  :deep(.base-select) {
    height: 44px;
  }

  :deep(:after) {
    height: 22px;
  }
}
</style>

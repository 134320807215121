<template>
  <div class="admin-input">
    <div class="admin-input_list">
      <div class="admin-input_list_group">
        <h2 class="admin-input_list_heading">会社情報</h2>
        <div class="admin-input_list-row pb-20">
          <label class="admin-input_list-row-item">
            法人名
            <span class="red">※</span>
          </label>
          <input
            v-model="comCooperationName"
            class="admin-input_list-row-input"
            :class="{ 'is-danger': errors.comCooperationName.isValid !== true }"
            maxlength="64"
            @input="
              validateOnInput(
                'comCooperationName',
                comCooperationName,
                validateRequire,
              )
            "
          />
          <span
            v-if="errors.comCooperationName.isValid !== true"
            class="fa-warning font-s error_message"
          >
            {{ errors.comCooperationName.message }}
          </span>
        </div>
        <div class="admin-input_list-row pb-20">
          <label class="admin-input_list-row-item">
            会社メールアドレス
            <span class="red">※</span>
          </label>
          <input
            v-model="comEmail"
            class="admin-input_list-row-input"
            :class="{ 'is-danger': errors.comEmail.isValid !== true }"
            maxlength="254"
            @input="validateOnInput('comEmail', comEmail, validateEmail)"
          />
          <span
            v-if="errors.comEmail.isValid !== true"
            class="fa-warning font-s error_message"
          >
            {{ errors.comEmail.message }}
          </span>
        </div>
        <div class="admin-input_list-row pb-20">
          <label class="admin-input_list-row-item">会社郵便番号</label>
          <input
            v-model="comZipcode"
            class="admin-input_list-row-input"
            :class="{ 'is-danger': errors.comZipcode.isValid !== true }"
            maxlength="7"
            @input="
              validateOnInput('comZipcode', comZipcode, validateZipcode, false)
            "
          />
          <span
            v-if="errors.comZipcode.isValid !== true"
            class="fa-warning font-s error_message"
          >
            {{ errors.comZipcode.message }}
          </span>
        </div>
        <div class="admin-input_list-row pb-20">
          <label class="admin-input_list-row-item">会社住所</label>
          <input
            v-model="comAddress"
            class="admin-input_list-row-input"
            maxlength="50"
          />
        </div>
        <div class="admin-input_list-row pb-20">
          <label class="admin-input_list-row-item">会社電話番号</label>
          <input
            v-model="comTel"
            class="admin-input_list-row-input"
            :class="{ 'is-danger': errors.comTel.isValid !== true }"
            maxlength="11"
            @input="validateOnInput('comTel', comTel, validateTel, false)"
          />
          <span
            v-if="errors.comTel.isValid !== true"
            class="fa-warning font-s error_message"
          >
            {{ errors.comTel.message }}
          </span>
        </div>
        <div class="admin-input_list-row pb-20">
          <label class="admin-input_list-row-item">
            管理者（姓）
            <span class="red">※</span>
          </label>
          <input
            v-model="comStaffLastname"
            class="admin-input_list-row-input"
            :class="{ 'is-danger': errors.comStaffLastname.isValid !== true }"
            maxlength="64"
            @input="
              validateOnInput(
                'comStaffLastname',
                comStaffLastname,
                validateRequire,
              )
            "
          />
          <span
            v-if="errors.comStaffLastname.isValid !== true"
            class="fa-warning font-s error_message"
          >
            {{ errors.comStaffLastname.message }}
          </span>
        </div>
        <div class="admin-input_list-row pb-20">
          <label class="admin-input_list-row-item">
            管理者（名）
            <span class="red">※</span>
          </label>
          <input
            v-model="comStaffFirstname"
            class="admin-input_list-row-input"
            :class="{
              'is-danger': errors.comStaffFirstname.isValid !== true,
            }"
            maxlength="64"
            @input="
              validateOnInput(
                'comStaffFirstname',
                comStaffFirstname,
                validateRequire,
              )
            "
          />
          <span
            v-if="errors.comStaffFirstname.isValid !== true"
            class="fa-warning font-s error_message"
          >
            {{ errors.comStaffFirstname.message }}
          </span>
        </div>
        <div class="admin-input_list-row pb-20">
          <label class="admin-input_list-row-item">
            管理者 メールアドレス
            <span class="red">※</span>
          </label>
          <input
            v-model="comStaffEmail"
            class="admin-input_list-row-input"
            :class="{ 'is-danger': errors.comStaffEmail.isValid !== true }"
            maxlength="254"
            @input="
              validateOnInput('comStaffEmail', comStaffEmail, validateEmail)
            "
          />
          <span
            v-if="errors.comStaffEmail.isValid !== true"
            class="fa-warning font-s error_message"
          >
            {{ errors.comStaffEmail.message }}
          </span>
        </div>
        <div class="admin-input_list-row">
          <label class="admin-input_list-row-item">管理者 電話番号</label>
          <input
            v-model="comStaffTel"
            class="admin-input_list-row-input"
            :class="{ 'is-danger': errors.comStaffTel.isValid !== true }"
            maxlength="11"
            @input="
              validateOnInput('comStaffTel', comStaffTel, validateTel, false)
            "
          />
          <span
            v-if="errors.comStaffTel.isValid !== true"
            class="fa-warning font-s error_message"
          >
            {{ errors.comStaffTel.message }}
          </span>
        </div>
      </div>
      <div class="admin-input_list_group">
        <h2 class="admin-input_list_heading">LINE設定</h2>
        <div class="admin-input_list-row pb-20">
          <label class="admin-input_list-row-item">
            LINE アカウント登録企業名
            <span class="red">※</span>
          </label>
          <input
            v-model="comName"
            class="admin-input_list-row-input"
            :class="{ 'is-danger': errors.comName.isValid !== true }"
            maxlength="64"
            @input="validateOnInput('comName', comName, validateRequire)"
          />
          <span
            v-if="errors.comName.isValid !== true"
            class="fa-warning font-s error_message"
          >
            {{ errors.comName.message }}
          </span>
        </div>
        <div class="admin-input_list-row pb-20">
          <label class="admin-input_list-row-item">
            LINE チャンネルシークレット
          </label>
          <input
            v-model="comSecret"
            class="admin-input_list-row-input"
            maxlength="32"
          />
        </div>
        <div class="admin-input_list-row pb-20">
          <label class="admin-input_list-row-item">LINE アクセストークン</label>
          <input
            v-model="comToken"
            class="admin-input_list-row-input"
            maxlength="172"
          />
        </div>
        <div class="admin-input_list-row pb-20">
          <label class="admin-input_list-row-item">LINE ID</label>
          <input
            v-model="comLineId"
            class="admin-input_list-row-input"
            maxlength="16"
          />
        </div>
        <div class="admin-input_list-row pb-20">
          <label class="admin-input_list-row-item">LINE QR</label>
          <input
            v-model="comLineQr"
            class="admin-input_list-row-input"
            maxlength="255"
          />
        </div>
        <div class="admin-input_list-row">
          <label class="admin-input_list-row-item">LINE友達URL</label>
          <input
            v-model="comLineUrl"
            class="admin-input_list-row-input"
            :class="{ 'is-danger': errors.comLineUrl.isValid !== true }"
            maxlength="150"
            @input="
              validateOnInput('comLineUrl', comLineUrl, validateUrl, false)
            "
          />
          <span
            v-if="errors.comLineUrl.isValid !== true"
            class="fa-warning font-s error_message"
          >
            {{ errors.comLineUrl.message }}
          </span>
        </div>
      </div>
      <div class="admin-input_list_group">
        <h2 class="admin-input_list_heading">MOCHICA設定</h2>
        <div class="admin-input_list-row pb-20">
          <label class="admin-input_list-row-item">
            現在利用プラン
            <span class="red">※</span>
          </label>
          <base-select v-model="comPlan">
            <option
              v-for="plan in mochicaPlans"
              :key="plan.id"
              :value="plan.id"
            >
              {{ plan.name_ja }}
            </option>
          </base-select>
        </div>
        <div class="admin-input_list-row pb-20 border-bottom">
          <label class="admin-input_list-row-item">
            基本年間プラン
            <span class="red">※</span>
          </label>
          <base-select v-model="comBasicPlan">
            <option
              v-for="plan in mochicaPlans"
              :key="plan.id"
              :value="plan.id"
            >
              {{ plan.name_ja }}
            </option>
          </base-select>
        </div>
        <div class="admin-input_list-row pt-20 pb-20">
          <label class="admin-input_list-row-item">アップグレードプラン①</label>
          <base-select v-model="comFirstUpgradePlan">
            <option :value="0" selected>選択してください</option>
            <option
              v-for="plan in mochicaPlans"
              :key="plan.id"
              :value="plan.id"
            >
              {{ plan.name_ja }}
            </option>
          </base-select>
        </div>
        <div class="admin-input_list-row pb-20 border-bottom">
          <label class="admin-input_list-row-item">
            アップグレードプラン①開始月
          </label>
          <el-date-picker
            v-model="comFirstUpgradeAt"
            class="admin-input_list-row-edit"
            type="month"
            format="YYYY/MM"
            value-format="YYYY-MM-DD"
            name="com_first_upgrade_at"
          />
        </div>
        <div class="admin-input_list-row pt-20 pb-20">
          <label class="admin-input_list-row-item">アップグレードプラン②</label>
          <base-select v-model="comSecondUpgradePlan">
            <option :value="0" selected>選択してください</option>
            <option
              v-for="plan in mochicaPlans"
              :key="plan.id"
              :value="plan.id"
            >
              {{ plan.name_ja }}
            </option>
          </base-select>
        </div>
        <div class="admin-input_list-row pb-20 border-bottom">
          <label class="admin-input_list-row-item">
            アップグレードプラン②開始月
          </label>
          <el-date-picker
            v-model="comSecondUpgradeAt"
            class="admin-input_list-row-edit"
            type="month"
            format="YYYY/MM"
            value-format="YYYY-MM-DD"
            name="com_second_upgrade_at"
          />
        </div>
        <div class="admin-input_list-row pt-20 pb-20">
          <label class="admin-input_list-row-item">無料開始月</label>
          <el-date-picker
            v-model="comFreeStartAt"
            class="admin-input_list-row-edit"
            type="month"
            format="YYYY/MM"
            value-format="YYYY-MM-DD"
            name="com_free_start_at"
          />
        </div>
        <div class="admin-input_list-row pb-20">
          <label class="admin-input_list-row-item">有料開始月</label>
          <el-date-picker
            v-model="comPaidStartAt"
            class="admin-input_list-row-edit"
            type="month"
            format="YYYY/MM"
            value-format="YYYY-MM-DD"
            name="com_paid_start_at"
          />
        </div>
        <div class="admin-input_list-row pb-20 border-bottom">
          <label class="admin-input_list-row-item">有料終了月</label>
          <el-date-picker
            v-model="comPaidEndAt"
            class="admin-input_list-row-edit"
            type="month"
            format="YYYY/MM"
            value-format="YYYY-MM-DD"
            name="com_paid_end_at"
          />
        </div>
        <div class="admin-input_list-row pt-20">
          <label class="admin-input_list-row-item">備考欄</label>
          <input
            v-model="comMemo"
            class="admin-input_list-row-input"
            maxlength="50"
          />
        </div>
      </div>
      <button-base
        class="admin-input_list-cancel-btn"
        button-text="キャンセル"
        :is-outlined="true"
        @click="$emit('cancelCompany')"
      />
      <button-base
        class="submit-btn"
        button-text="企業を登録"
        :is-loading="isLoading"
        :rejected-admin-ids="[2]"
        @click="registCompany"
      />
    </div>
    <div v-if="registView === true" class="admin-input_regist-view">
      <div class="regist-block">
        <div class="regist-block">
          <p class="regist-block__title">MOCHICA 企業登録情報結果</p>
          <ul class="regist-block__list">
            <li class="regist-block__list-item">
              <span>企業ID</span>
              {{ registCompany.id }}
            </li>
            <li class="regist-block__list-item">
              <span>企業プラン</span>
              {{ getPlanDataById(company.data.plan).name_ja }}
            </li>
            <li class="regist-block__list-item">
              <span>企業名</span>
              {{ company.data.name }}
            </li>
            <li class="regist-block__list-item">
              <span>企業メールアドレス</span>
              {{ company.data.email }}
            </li>
            <li class="regist-block__list-item">
              <span>企業 LINE ID</span>
              {{ company.data.line_id }}
            </li>
            <li class="regist-block__list-item">
              <span>企業 LINE Webhook</span>
              {{ company.data.webhook }}
            </li>
          </ul>
          <p class="regist-block__title">MOCHICA 担当者登録結果</p>
          <ul class="regist-block__list">
            <li class="regist-block__list-item">
              <span>担当者名</span>
              {{ staff.data.lastname }}{{ staff.data.firstname }}
            </li>
            <li class="regist-block__list-item">
              <span>担当者 メールアドレス</span>
              {{ staff.data.email }}
            </li>
            <li class="regist-block__list-item">
              <span>担当者 パスワード</span>
              {{ staff.data.password }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';

import { mochicaPlans, getPlanDataById } from '@/defines/plan';
import BaseSelect from '@/components/ui/select/BaseSelect.vue';
import adminService from '@/services/admin';
import useValidation from '@/composables/useValidation';

export default defineComponent({
  name: 'AdminRegisterCompany',
  components: {
    BaseSelect,
  },
  emits: ['cancelCompany'],
  setup(props, context) {
    const {
      errors,
      resetError,
      resetErrors,
      validateEmail,
      validateUrl,
      validateOnInput,
      validateRequire,
      validateStorage,
      validateTel,
      validateZipcode,
    } = useValidation([
      'comName',
      'comEmail',
      'comZipcode',
      'comTel',
      'comStaffLastname',
      'comStaffFirstname',
      'comStaffEmail',
      'comStaffTel',
      'comFileStorage',
      'comLineUrl',
      'comCooperationName',
      'comBasicPlan',
      'comFirstUpgradePlan',
      'comFirstUpgradeAt',
      'comSecondUpgradePlan',
      'comSecondUpgradeAt',
      'comFreeStartAt',
      'comPaidStartAt',
      'comPaidEndAt',
      'comMemo',
    ]);
    const store = useStore();
    const isLoading = ref(false);
    const comName = ref('');
    const comPlan = ref(2);
    const comEmail = ref('');
    const comSecret = ref('');
    const comToken = ref('');
    const comLineId = ref('');
    const comLineQr = ref('');
    const comLineUrl = ref('');
    const comTel = ref('');
    const comZipcode = ref('');
    const comAddress = ref('');
    const comStaffLastname = ref('');
    const comStaffFirstname = ref('');
    const comStaffEmail = ref('');
    const comStaffTel = ref('');
    const comCooperationName = ref('');
    const comBasicPlan = ref(2);
    const comFirstUpgradePlan = ref(0);
    const comFirstUpgradeAt = ref(null);
    const comSecondUpgradePlan = ref(0);
    const comSecondUpgradeAt = ref(null);
    const comFreeStartAt = ref(null);
    const comPaidStartAt = ref(null);
    const comPaidEndAt = ref(null);
    const comMemo = ref(null);

    const registView = ref(false);
    const staff = reactive({
      data: { firstname: '', lastname: '', email: '', password: '' },
    });
    const company = reactive({
      data: { name: '', email: '', webhook: '', line_id: '' },
    });

    // methods
    const validateAll = () => {
      resetErrors();
      let isValid = true;
      if (!validateRequire('comName', comName.value)) isValid = false;
      if (!validateEmail('comEmail', comEmail.value)) isValid = false;
      if (!validateZipcode('comZipcode', comZipcode.value, false)) {
        isValid = false;
      }
      if (!validateTel('comTel', comTel.value, false)) isValid = false;
      if (!validateUrl('comLineUrl', comLineUrl.value, false)) {
        isValid = false;
      }
      if (!validateRequire('comStaffLastname', comStaffLastname.value)) {
        isValid = false;
      }
      if (!validateRequire('comStaffFirstname', comStaffFirstname.value)) {
        isValid = false;
      }
      if (!validateEmail('comStaffEmail', comStaffEmail.value)) {
        isValid = false;
      }
      if (!validateTel('comStaffTel', comStaffTel.value, false)) {
        isValid = false;
      }
      if (!validateRequire('comCooperationName', comCooperationName.value))
        isValid = false;
      return isValid;
    };
    const registCompany = async () => {
      if (validateAll() !== true) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          type: false,
          message: '入力内容を確認して下さい',
        });
        return;
      }
      isLoading.value = true;
      const res = await adminService.createCompany(
        comEmail.value,
        comToken.value,
        comName.value,
        parseInt(comPlan.value),
        comTel.value,
        comZipcode.value,
        comAddress.value,
        comSecret.value,
        comLineId.value,
        comLineQr.value,
        comLineUrl.value,
        comStaffEmail.value,
        comStaffLastname.value,
        comStaffTel.value,
        comStaffFirstname.value,
        comCooperationName.value,
        parseInt(comBasicPlan.value),
        parseInt(comFirstUpgradePlan.value) > 0
          ? parseInt(comFirstUpgradePlan.value)
          : null,
        comFirstUpgradeAt.value
          ? `${moment(comFirstUpgradeAt.value).format('YYYY-MM-DD')} 09:00:00`
          : null,
        parseInt(comSecondUpgradePlan.value) > 0
          ? parseInt(comSecondUpgradePlan.value)
          : null,
        comSecondUpgradeAt.value
          ? `${moment(comSecondUpgradeAt.value).format('YYYY-MM-DD')} 09:00:00`
          : null,
        comFreeStartAt.value
          ? `${moment(comFreeStartAt.value).format('YYYY-MM-DD')} 09:00:00`
          : null,
        comPaidStartAt.value
          ? `${moment(comPaidStartAt.value).format('YYYY-MM-DD')} 09:00:00`
          : null,
        comPaidEndAt.value
          ? `${moment(comPaidEndAt.value).format('YYYY-MM-DD')} 09:00:00`
          : null,
        comMemo.value,
      );
      isLoading.value = false;
      if (res.success !== true) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.message,
          type: false,
        });
        return;
      }
      company.data = res.company.company;
      staff.data = res.company.staff;
      reset();
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
    };
    const reset = () => {
      comName.value = '';
      comPlan.value = 2;
      comEmail.value = '';
      comTel.value = '';
      comZipcode.value = '';
      comAddress.value = '';
      comSecret.value = '';
      comToken.value = '';
      comLineId.value = '';
      comLineQr.value = '';
      comLineUrl.value = '';
      comStaffLastname.value = '';
      comStaffFirstname.value = '';
      comStaffTel.value = '';
      registView.value = true;
      comCooperationName.value = '';
      comBasicPlan.value = 2;
      comFirstUpgradePlan.value = 0;
      comFirstUpgradeAt.value = null;
      comSecondUpgradePlan.value = 0;
      comSecondUpgradeAt.value = null;
      comFreeStartAt.value = null;
      comPaidStartAt.value = null;
      comPaidEndAt.value = null;
      comMemo.value = '';
      resetErrors();
    };

    return {
      errors,
      comName,
      comPlan,
      comEmail,
      comSecret,
      comToken,
      comLineId,
      comLineQr,
      comLineUrl,
      comTel,
      comZipcode,
      comAddress,
      comStaffLastname,
      comStaffFirstname,
      comStaffEmail,
      comStaffTel,
      comCooperationName,
      comBasicPlan,
      comFirstUpgradePlan,
      comFirstUpgradeAt,
      comSecondUpgradePlan,
      comSecondUpgradeAt,
      comFreeStartAt,
      comPaidStartAt,
      comPaidEndAt,
      comMemo,
      registView,
      company,
      staff,
      isLoading,
      mochicaPlans: mochicaPlans.filter(plan => plan.is_show === true),
      resetError,
      getPlanDataById,
      registCompany,
      validateEmail,
      validateUrl,
      validateOnInput,
      validateRequire,
      validateStorage,
      validateTel,
      validateZipcode,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';
@import '@/assets/datepicker.scss';

.admin-input {
  @include flex_center();
  align-items: flex-start;
  padding: 2rem;
  line-height: 1.5;
  .admin-input_list {
    background: $white;
    padding: 20px;
    width: 50rem;
    margin-right: 2rem;
    .submit-btn {
      @include flex_center($justify: center);
      @include btn_hover();
      cursor: pointer;
      width: 100%;
      background: $mochica_color;
      color: $white;
      font-weight: 700;
      border-radius: 3rem;
      height: 4rem;
      margin-top: 2rem;
      button {
        border: none;
        font-weight: bold;
      }
    }
  }
}

.admin-input_list_heading {
  padding: 10px 16px;
  background: $mochica_color_gray2;
  font-weight: bold;
  border-radius: 4px;
  font-size: $font_16;
  margin-bottom: 20px;
}
.admin-input_list_group {
  &:not(:first-child) {
    margin-top: 32px;
  }
}

.admin-input_list-row {
  &.border-bottom {
    border-bottom: 1px solid #f1f1f1;
  }
  &.pb-20 {
    padding-bottom: 20px;
  }
  &.pt-20 {
    padding-top: 20px;
  }

  :deep(.wrapper) {
    width: 320px;
  }

  :deep(.base-select) {
    height: 44px;
  }

  :deep(:after) {
    height: 22px;
  }
}
.admin-input_list-row-item {
  display: block;
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 0.8rem;
}
.admin-input_list-row-input {
  display: block;
  border: 1px solid $border_color_gray;
  padding: 16px;
  width: 320px;
  height: 44px;
  border-radius: 4px !important;
}

.admin-input_list-row-subitem {
  margin-top: 1.5rem;
}

.admin-input_regist-view {
  @extend .admin-input_list;
  background: #eaeaea;
  margin-right: 0;
}

.regist-block__title {
  font-weight: bold;
  font-size: 1.3rem;
  margin-bottom: 0.6rem;

  &:nth-of-type(2) {
    margin-top: 2.4rem;
  }
}
.regist-block__list {
  padding: 2rem;
  background: $white;
  border-radius: 0.4rem;
}
.regist-block__list-item {
  margin-bottom: 0.8rem;
  padding-bottom: 0.8rem;
  border-bottom: 1px solid #f1f1f1;

  &:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
}

input:focus {
  border: 1px green solid;
}
input:focus.is-danger,
.is-danger {
  border: 1px $mochica_color_red solid;
}
.fa-warning {
  color: $mochica_color_red;
  padding-top: 4px;
  font-size: 1.2rem;
}
span.red {
  color: #f00;
}

.switch {
  > label {
    margin: 0 4px 0 3px;
  }
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('../../../../assets/img/arrow_down_mini_blue.svg');
  background-position: right 10px center;
  background-size: auto 10px;
}

.admin-input_list-row-edit {
  display: block;
  border: 1px solid $border_color_gray;
  padding: 0.4rem;
  width: 20rem;
}

:deep(.el-input) {
  width: 320px;
  height: 44px;
}

.admin-input_list-cancel-btn {
  width: 100%;
  height: 48px;
  margin-top: 24px;
  border-radius: 24px !important;
}
</style>
